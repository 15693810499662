import { h, render } from "preact"
import {
  Menu,
  Close,
  ArrowForwardIos,
  ArrowBackIos,
  ExpandMore,
  ExpandLess,
  Facebook,
  WhatsApp,
  Twitter,
  Instagram,
  GetApp,
  Mail,
  YouTube,
} from "@material-ui/icons"
import { qsa } from "./dom"

const icons = {
  Menu: <Menu />,
  Close: <Close />,
  ArrowForwardIos: <ArrowForwardIos />,
  ArrowBackIos: <ArrowBackIos />,
  ExpandMore: <ExpandMore />,
  ExpandLess: <ExpandLess />,
  Facebook: <Facebook />,
  WhatsApp: <WhatsApp />,
  Twitter: <Twitter />,
  Instagram: <Instagram />,
  GetApp: <GetApp />,
  Mail: <Mail />,
  YouTube: <YouTube />,
}

export const initHybridIcons = () => {
  const iconElements = qsa("i.hybrid")

  if (iconElements.length)
    iconElements.map((element) => {
      if (icons[element.dataset.icon]) {
        render(icons[element.dataset.icon], element)
      } else {
        console.log(`${element.textContent} icon undefined`)
      }
    })
}
