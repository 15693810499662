import "cropperjs/dist/cropper.css"
import Cropper from "cropperjs"
// import { h, render } from "preact"
// import { useRef } from "preact/hooks"
import { qs } from "./dom"

const cropHeight = 582
const cropWidth = 532

export const initImageCropping = () => {
  const form = qs("#testimonial-create")

  if (form) new ImageCropping(form)
}

// export const initImageCropping = () => {
//   const form = qs("#testimonial-create")

//   if (form) {
//     const cropper = qs("#image-cropping", form)
//     render(<ImageCropper {...{ form }} />, cropper)
//   }
// }

// const ImageCropper = ({ form }) => {
//   const image = useRef()

//   const cropper = new Cropper(image.current, {
//     viewMode: 2,
//     aspectRatio: 1 / 1,
//     crop: (/* e */) => {
//       // console.log(e.detail.x)
//     },
//     guides: false,
//   })

//   const getCroppedImage = async () => {
//     return new Promise((resolve) => {
//       if (imageCropped) {
//         let croppedImage = this.cropper.getCroppedCanvas({
//           width: cropWidth,
//           height: cropHeight,
//         })
//         croppedImage.toBlob(resolve, "image/jpeg")
//       } else {
//         resolve()
//       }
//     })
//   }

//   const formSubmit = (e) => {
//     e.preventDefault()

//     const croppedImage = await getCroppedImage()
//   }
//   form.onsubmit = formSubmit

//   return
// }

class ImageCropping {
  constructor(form) {
    this.form = form
    this.cropperEl = this.form.querySelector("#image-cropping")
    this.img = this.cropperEl.querySelector("img")
    this.fileInput = this.form.querySelector('input[type="file"]')
    this.fileInput.addEventListener("change", this.setFile)
    this.form.onsubmit = this.submit
    this.imageCropped = false

    this.currentPortraitLink = this.form.querySelector(
      ".widget--clearablefileinput a"
    )
    if (this.currentPortraitLink) {
      let portraitCell = this.currentPortraitLink.parentNode
      portraitCell.classList.add("image-cropping__portrait-cell")
      let thumb = document.createElement("img")
      thumb.src = this.currentPortraitLink.href
      portraitCell.insertBefore(thumb, portraitCell.firstChild.nextSibling)
    }
  }

  setFile = () => {
    let files = this.fileInput.files

    if (files.length >= 1) {
      let file = files[0]
      const imageType = /^image\//

      if (!imageType.test(file.type)) {
        // console.error('not a valid image file')
        return
      }

      this.cropper = new Cropper(this.img, {
        viewMode: 2,
        aspectRatio: cropWidth / cropHeight,
        crop: (/* e */) => {
          // console.log(e.detail.x)
        },
        guides: false,
      })

      const reader = new FileReader()
      reader.onload = (e) => {
        this.cropperEl.classList.add("image-cropping--show")
        this.cropper.destroy()
        let cropperCont = this.cropperEl.querySelector(".cropper-container")
        cropperCont && cropperCont.parentNode.removeChild(cropperCont)
        this.cropper.replace(e.target.result)
        this.imageCropped = true
      }

      reader.readAsDataURL(file)
    }
  }

  getCroppedImage = () => {
    return new Promise((resolve) => {
      if (this.imageCropped) {
        let croppedImage = this.cropper.getCroppedCanvas({
          width: cropWidth,
          height: cropHeight,
        })
        croppedImage.toBlob(resolve, "image/jpeg")
      } else {
        // resolve immediately
        resolve()
      }
    })
  }

  submit = (e) => {
    e.preventDefault()
    // console.log('submit()')

    let self = this

    this.getCroppedImage()
      .then((blob) => {
        // it could have been so easy:
        // let formData = new FormData(this.form)
        // but Safari does only know formData.append()
        // therefore we need to loop over all form elements

        let formData = new FormData()

        for (let i = 0; i < this.form.elements.length; i++) {
          let formElement = this.form.elements[i]
          if (formElement.name != "portrait" || !blob) {
            formData.append(formElement.name, formElement.value)
          } else {
            formData.append("portrait", blob, "portrait.jpg")
          }
        }

        return formData
      })
      .then((formData) => {
        return fetch(this.form.action, {
          method: "POST",
          credentials: "same-origin",
          headers: {
            "X-CSRFToken": document.cookie.match(/csrftoken=(\w+?)\b/)[1],
          },
          body: formData,
        })
      })
      .then((response) => {
        return response.status == 201 ? response.json() : response.text()
      })
      .then((data) => {
        // either a JSON object is returned or a HTML string
        if (typeof data == "object" && data.next) {
          window.location.href = data.next
        } else {
          // replace the <form> tag with the new one
          let responseDoc =
            document.implementation.createHTMLDocument("response")
          responseDoc.documentElement.innerHTML = data

          let newForm = qs("#testimonial-create", responseDoc)
          self.form.parentNode.replaceChild(newForm, self.form)
          self.form = newForm
          new ImageCropping(self.form)

          let errorneousField = qs(".error input", self.form)
          if (errorneousField) {
            window.location.hash = errorneousField.id
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
