import { h, Fragment, render } from "preact"
import { useState } from "preact/hooks"
import { qs } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"
import { gettext } from "../utils/gettext"

export const initNewsletterForm = () => {
  const newsletterFormElement = qs("#newsletter-form")

  if (newsletterFormElement)
    render(
      <NewsletterForm
        endpoint={new Endpoint(newsletterFormElement.dataset.endpoint)}
      />,
      newsletterFormElement
    )
}

const NewsletterForm = ({ endpoint }) => {
  const [status, setStatus] = useState("")
  const [statusText, setStatusText] = useState("")

  const onsubmit = async (e) => {
    e.preventDefault()
    setStatus("loading")

    const formData = new FormData(e.target)
    let response = await endpoint.postData(formData)

    setStatus(response.status)
    setStatusText(response.statusText)
  }

  return (
    <form onSubmit={onsubmit}>
      {status === "error" && (
        <div class="text-red-700 mb-4">
          {gettext("Fehler beim Absenden des Formulars.")}
        </div>
      )}
      {status === "" ? (
        <>
          <div class="flex flex-col mb-4">
            <RadioInput
              label={gettext("Neutrale Anrede")}
              name={"salutation"}
              id={"salutation_0"}
              required
            />
            <RadioInput
              label={gettext("Frau")}
              name={"salutation"}
              id={"salutation_1"}
              required
            />
            <RadioInput
              label={gettext("Herr")}
              name={"salutation"}
              id={"salutation_2"}
              required
            />
          </div>
          <TextInput
            label={gettext("Vorname")}
            name={"given_name"}
            type={"text"}
            maxLength={"50"}
            required
          />
          <TextInput
            label={gettext("Nachname")}
            name={"family_name"}
            type={"text"}
            maxLength={"50"}
            required
          />
          <TextInput
            label={gettext("Email")}
            name={"email"}
            type={"email"}
            maxLength={"50"}
            required
          />
          <div class="flex justify-center mt-8 mb-4">
            <input
              type="submit"
              class="block px-8 py-2 border-2 cursor-pointer bg-black border-black text-white font-bold rounded-md hover:bg-white focus:bg-white hover:text-black focus:text-black"
              value={gettext("Anmelden")}
            />
          </div>
        </>
      ) : (
        <div class="">{statusText}</div>
      )}
    </form>
  )
}

const TextInput = ({ label, name, type, maxLength, required }) => {
  return (
    <div class="mb-4">
      <label for={name} class="block">
        {label}*
      </label>
      <input
        class="block w-full"
        name={name}
        type={type}
        maxLength={maxLength}
        value=""
        required={required}
      />
    </div>
  )
}

const RadioInput = ({ label, name, id, required }) => {
  return (
    <div>
      <input
        id={id}
        name={name}
        type="radio"
        value={label}
        required={required}
      />
      <label class="ml-2" for={id}>
        {label}
      </label>
    </div>
  )
}
