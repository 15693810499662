import { qs, qsa } from "../utils/dom"

export const initFAQ = () => {
  const faqEl = qs("#faq")

  if (faqEl) {
    const faqItems = qsa("details", faqEl)

    if (faqItems.length > 1) {
      faqItems.map((item) => {
        item.addEventListener("click", (e) => {
          faqItems.map((otherItem) => {
            if (
              e.target.closest("details") != otherItem &&
              otherItem.hasAttribute("open")
            ) {
              otherItem.removeAttribute("open")
            }
          })
        })
      })
    }
  }
}
