const searchDirs = [
  "./app/templates/**/*.html",
  "./testimonials/templates/**/*.html",
  "./articles/templates/**/*.html",
  "./faq/templates/**/*.html",
  "./download_list/templates/**/*.html",
  "./supporters/templates/**/*.html",
  "./engagement_boxes/templates/**/*.html",
  "./app/templates/**/*.svg",
  "./frontend/js/components/**/*.js",
]

module.exports = {
  mode: "jit",
  purge: searchDirs,
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
    },
    fontFamily: {
      sans: ["Roboto", "sans-serif"],
    },
    colors: {
      transparent: "transparent",
      white: "#FFFFFF",
      red: "#f05b61",
      black: "#000000",
      yellow: "#f0ed74",
      gray: "#5a5a5a",
    },
    extend: {
      gridTemplateColumns: {
        engagement: "repeat(auto-fit, minmax(290px, 1fr))",
      },
      typography: {
        DEFAULT: {
          css: {
            h1: {
              "line-height": 1.25,
            },
          },
        },
      },
    },
  },
  variants: {},
  plugins: [require("@tailwindcss/typography")],
}
