import { h, Fragment, render } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import { qsa } from "../utils/dom"
import { useBreakpoints } from "../utils/breakpoints"
import {
  Facebook,
  Twitter,
  WhatsApp,
  GetApp,
  Search,
  LinkedIn,
} from "@material-ui/icons"
import { gettext } from "../utils/gettext"

const testimonialsPerPage = 12

export const initTestimonials = () => {
  const testimonialsEl = qsa(".testimonials")
  if (testimonialsEl)
    testimonialsEl.map((t, i) => {
      render(
        <TestimonialsApp
          key={i}
          data={JSON.parse(t.dataset.testimonials || {})}
          selected={t.dataset.selected || ""}
          listBase={t.dataset.listBase || ""}
        />,
        t
      )
    })
}

const TestimonialsApp = ({ data, selected, listBase }) => {
  // console.log("jsonData", data)
  // console.log("selected", selected)

  const getCols = useBreakpoints("md") ? 4 : 2

  const [activeBox, activateBox] = useState(selected)
  const [sliceNo, setSliceNo] = useState(1)
  const [cols, setCols] = useState(getCols)
  const [searchWord, setSearchWord] = useState("")
  const [shownTestimonials, setShownTestimonials] = useState(
    data.slice(0, testimonialsPerPage)
  )

  useEffect(() => {
    window.onresize = () => {
      setCols(getCols)
    }
    const term = searchWord.toLowerCase()
    const filteredItems = data.filter((o) => o.search_string.indexOf(term) > -1)
    setShownTestimonials(filteredItems.slice(0, testimonialsPerPage))
  }, [searchWord, data])

  // console.log(shownTestimonials, data)

  return (
    <>
      <div class="flex">
        <div class="relative mx-auto md:mr-0">
          <input
            class="my-4 p-3 border-solid border-2 border-black rounded-md"
            type="text"
            value={searchWord}
            placeholder={gettext("search")}
            onInput={(e) => setSearchWord(e.target.value)}
          />
          <i class="absolute w-8 h-8 top-6 right-2 text-black">
            <Search />
          </i>
        </div>
      </div>
      {/* tailwind purge workaround */}
      <div class={`grid ${cols === 2 ? "grid-cols-2" : "grid-cols-4"} gap-4`}>
        {shownTestimonials.length < 1 && (
          <div class="">{gettext("Keine Botschaften gefunden.")}</div>
        )}
        {/* wrap as row slices */}
        {shownTestimonials
          .reduce((slices, testimonial, i) => {
            if (i % cols === 0) slices.push([])
            slices[slices.length - 1].push(testimonial)
            return slices
          }, [])
          .map((testimonials, i) => {
            return (
              <TestimonialRow
                key={i}
                {...{ testimonials, activeBox, activateBox, listBase }}
              />
            )
          })}
      </div>
      {shownTestimonials.length % testimonialsPerPage == 0 &&
        shownTestimonials.length < data.length && (
          <div class="w-full text-center my-8">
            <button
              type="button"
              class="px-8 py-3 border-2 bg-black border-black text-white font-bold rounded-md hover:bg-white focus:bg-white hover:text-black focus:text-black"
              onClick={() => {
                let nextSlice = sliceNo + 1
                setShownTestimonials(
                  data.slice(0, nextSlice * testimonialsPerPage)
                )
                setSliceNo(nextSlice)
              }}
            >
              {gettext("show more")}
            </button>
          </div>
        )}
    </>
  )
}

const TestimonialRow = ({ testimonials, activeBox, activateBox, listBase }) => {
  const activeDropdownRef = useRef()

  const activeDropdown = testimonials.find((t) => {
    return t.id === activeBox
  })

  useEffect(() => {
    if (activeDropdown && activeDropdownRef.current)
      activeDropdownRef.current.scrollIntoView({ behavior: "smooth" })
    // TODO: only scroll if necessary
  }, [activeDropdown])

  return (
    <>
      {testimonials.map((testimonial) => {
        return (
          <TestimonialBox
            key={testimonial.id}
            data={testimonial}
            isActive={
              activeDropdown ? activeDropdown.id === testimonial.id : false
            }
            activateBox={() => {
              activateBox(testimonial.id)
            }}
          />
        )
      })}
      {activeDropdown && (
        <div
          ref={activeDropdownRef}
          class="col-span-full active-dropdown z-20 bg-red p-4 m-[4px]"
        >
          <TestimonialDetails data={activeDropdown} listBase={listBase} />
        </div>
      )}
    </>
  )
}

/**
 *  data-properties: id, name, profession, portrait, search_string, sharing_image, statement, url, highlighted_at
 */

const TestimonialBox = ({ data, isActive, activateBox }) => {
  return (
    <button
      class="relative border-transparent border-4 hover:border-red focus:border-red"
      key={data.id}
      onClick={activateBox}
    >
      <img
        class=""
        src={data.portrait}
        alt={`${gettext("Portrait of")} ${data.name}`}
      />
      <div
        class={`absolute bottom-0 p-2 pt-4 w-full text-lg text-white bg-gradient-to-t from-black ${
          isActive ? "transition-opacity opacity-0" : ""
        }`}
      >
        {data.name}
      </div>
      <div
        class={`rotate-45 absolute z-10 w-16 h-16 -bottom-16 left-8 bg-red ${
          isActive ? "block" : "hidden"
        }`}
      />
    </button>
  )
}

const TestimonialDetails = ({ data, listBase }) => {
  const sharingText = encodeURI(
    `${data.name} ${gettext("supports the initiative")} \n`
  )
  const sharingURL = `${window.location.protocol}//${window.location.hostname}${listBase}${data.id}/`
  const langCode = document.documentElement.lang
  let hashTag
  if (langCode === "fr") {
    hashTag = "enfantssanstabac"
  } else if (langCode === "it") {
    hashTag = "giovanisenzatabacco"
  } else {
    hashTag = "kinderohnetabak"
  }

  return (
    <>
      <h3 class="text-lg font-bold">{data.name}</h3>
      <p class="text-black">{data.profession}</p>
      <p class="italic my-3 max-w-prose">«{data.statement}»</p>
      <div class="flex space-x-2 justify-end">
        <ShareLink
          title={gettext("Auf Facebook teilen")}
          url={`https://www.facebook.com/sharer.php?u=${sharingURL}`}
        >
          <i class="w-8 h-8">
            <Facebook />
          </i>
        </ShareLink>
        <ShareLink
          title={gettext("Auf Twitter teilen")}
          url={`https://twitter.com/share?url=${sharingURL}&hashtags=${hashTag}`}
        >
          <i class="w-8 h-8">
            <Twitter />
          </i>
        </ShareLink>
        <ShareLink
          title={gettext("Auf Whatsapp teilen")}
          url={`https://api.whatsapp.com/send?phone=&text=${sharingText}${sharingURL}`}
          icon="whatsapp"
        >
          <i class="w-8 h-8">
            <WhatsApp />
          </i>
        </ShareLink>
        <ShareLink
          title={gettext("Auf LinkedIn teilen")}
          url={`https://www.linkedin.com/sharing/share-offsite/?url=${sharingURL}`}
          icon="linkedin"
        >
          <i class="w-8 h-8">
            <LinkedIn />
          </i>
        </ShareLink>
        <DownloadLink src={data.sharing_image}>
          <i class="w-8 h-8">
            <GetApp />
          </i>
        </DownloadLink>
      </div>
    </>
  )
}

const ShareLink = ({ title, url, children }) => {
  return (
    <div>
      <a href={url} title={title} target="_blank" rel="noreferrer">
        <span class="sr-only">{title}</span>
        {children}
      </a>
    </div>
  )
}

const DownloadLink = ({ src, children }) => {
  const title = gettext("Bild der Botschaft zum Teilen")

  return (
    <div>
      <a href={src} title={title} download>
        <span class="sr-only">{title}</span>
        {children}
      </a>
    </div>
  )
}
