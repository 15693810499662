import "./main.css"
import "./css/cookiecontrol.css"

import { initToggleNav } from "./js/components/nav"
import { onReady } from "./js/utils/dom"
import { initTestimonials } from "./js/components/testimonials"
import { initImageCropping } from "./js/utils/imageCropping"
import { initFAQ } from "./js/components/faq"
import { initHybridIcons } from "./js/utils/hybridIcons"
import { initNewsletterForm } from "./js/components/newsletterForm"

onReady(() => {
  initHybridIcons()
  initToggleNav()
  initTestimonials()
  initImageCropping()
  initFAQ()
  initNewsletterForm()
})
